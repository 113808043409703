import { Schema } from "@effect/schema";
export const RecallStatus = Schema.declare((input) => typeof input === "string", {
    identifier: "RecallStatus",
    description: "The status of a recall bot",
});
export const RecallCalendarMeeting = Schema.Struct({
    id: Schema.NonEmptyString,
    start_time: Schema.NullishOr(Schema.String),
    end_time: Schema.NullishOr(Schema.String),
    calendar_user: Schema.NullishOr(Schema.Struct({
        id: Schema.String,
        external_id: Schema.NullishOr(Schema.String),
    })),
});
export const RecallRecording = Schema.Struct({
    id: Schema.NonEmptyString,
    start_time: Schema.NullishOr(Schema.String),
    completed_at: Schema.NullishOr(Schema.String),
});
export const RecallStatusChange = Schema.Struct({
    code: RecallStatus,
    message: Schema.NullOr(Schema.String),
    created_at: Schema.String,
    sub_code: Schema.NullOr(Schema.String),
});
export const RecallMeetingMetadata = Schema.Struct({
    title: Schema.String,
    zoom_meeting_uuid: Schema.NullishOr(Schema.String),
});
export const RecallMeetingParticipant = Schema.Struct({
    id: Schema.NonEmptyString,
    name: Schema.NullishOr(Schema.String),
    events: Schema.NullishOr(Schema.Array(Schema.Struct({
        code: Schema.String,
        created_at: Schema.NullishOr(Schema.String),
    }))),
    is_host: Schema.NullishOr(Schema.Boolean),
    platform: Schema.NullishOr(Schema.String),
    extra_data: Schema.NullishOr(Schema.Record({ key: Schema.String, value: Schema.Unknown })),
});
export const RecallMeetingUrl = Schema.Struct({
    tenant_id: Schema.NullishOr(Schema.String),
    thread_id: Schema.NullishOr(Schema.String),
    meeting_id: Schema.NullishOr(Schema.String),
    meeting_password: Schema.NullishOr(Schema.String),
    organizer_id: Schema.NullishOr(Schema.String),
    message_id: Schema.NullishOr(Schema.String),
    platform: Schema.NullishOr(Schema.String),
    business_meeting_password: Schema.NullishOr(Schema.String),
    business_meeting_id: Schema.NullishOr(Schema.String),
});
export const RecallBot = Schema.Struct({
    iId: Schema.NonEmptyString.annotations({
        description: "The associated interview id",
    }),
    id: Schema.NonEmptyString.annotations({ description: "The bot's id" }),
    video_url: Schema.NullishOr(Schema.String),
    media_retention_end: Schema.NullishOr(Schema.String),
    status_changes: Schema.NullishOr(Schema.Array(RecallStatusChange)),
    meeting_metadata: Schema.NullishOr(Schema.Array(RecallMeetingMetadata)),
    meeting_participants: Schema.NullishOr(Schema.Array(RecallMeetingParticipant)),
    meeting_url: Schema.NullishOr(Schema.Union(Schema.String, RecallMeetingUrl)),
    metadata: Schema.optional(Schema.Record({ key: Schema.String, value: Schema.Unknown })),
    join_at: Schema.NullishOr(Schema.String),
    calendar_meetings: Schema.optional(Schema.Array(RecallCalendarMeeting)),
    recording: Schema.NullOr(Schema.String),
    recordings: Schema.optional(Schema.Array(RecallRecording)),
});
