import { Accordion } from "@fluentui/react-components";

import type { Interview } from "@aglocal/schema/Interview";
import type { DarkquellInferredSkill } from "@aglocal/schema/Darkquell";
import type { LLMNEREntity } from "@aglocal/schema/LLM-NER";

import ConnectedDetailsAccordionItem from "./ConnectedDetailsAccordionItem";
import SkillsAccordionItem from "./SkillsAccordionItem";
import TranscriptAccordionItem from "./TranscriptAccordionItem";

export interface SidePanelAccordionProps {
  interview: Interview;
  skills: readonly DarkquellInferredSkill[];
  entities: readonly LLMNEREntity[];
}

export default function SidePanelAccordion({
  interview,
  skills,
  entities,
}: SidePanelAccordionProps) {
  return (
    <Accordion defaultOpenItems={["transcript"]} multiple>
      <ConnectedDetailsAccordionItem
        name="connected-details"
        interview={interview}
      />
      <SkillsAccordionItem name="skills" skills={skills} entities={entities} />
      <TranscriptAccordionItem
        name="transcript"
        interview={interview}
        skills={skills}
      />
    </Accordion>
  );
}
