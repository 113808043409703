import { IntlErrorCode, OnErrorFn } from "@formatjs/intl";

const SEEN_MISSING_TRANSLATION = new Set<string>();

export const logIntlError: OnErrorFn = (error) => {
  switch (error.code) {
    case IntlErrorCode.MISSING_TRANSLATION: {
      const key = error.descriptor?.id ?? error.message;
      if (!SEEN_MISSING_TRANSLATION.has(key)) {
        console.log(error.message);
        SEEN_MISSING_TRANSLATION.add(key);
      }
      break;
    }
    default:
      reportError(error);
  }
};
