import { useCallback, useEffect, useMemo } from "react";
import {
  Control,
  useController,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";

import { logEvent } from "firebase/analytics";

import { ArrowSyncRegular } from "@fluentui/react-icons";
import { Field, Textarea, Button, Spinner } from "@fluentui/react-components";

import type { Interview } from "@aglocal/schema/Interview";

import { useFirebaseAnalytics } from "@aglocal/web/firebase";
import useReportError from "@aglocal/web/hooks/useReportError";

import Stack from "@aglocal/web/components/Stack";
import { Span } from "@aglocal/web/components/Typography";

import useSummary from "@/hooks/useSummary";

import { PostMeetingFormWithSummary } from "../form";

interface SummaryState {
  id: string;
  cache: Map<string, string>;
  changed: Map<string, boolean>;
}

export interface SummaryFieldProps {
  interview: Interview;
  control: Control<PostMeetingFormWithSummary>;
  getFieldState: UseFormGetFieldState<PostMeetingFormWithSummary>;
  getValues: UseFormGetValues<PostMeetingFormWithSummary>;
  resetField: UseFormResetField<PostMeetingFormWithSummary>;
  setValue: UseFormSetValue<PostMeetingFormWithSummary>;
}

export default function SummaryField({
  interview,
  control,
  getFieldState,
  getValues,
  resetField,
  setValue,
}: SummaryFieldProps) {
  const analytics = useFirebaseAnalytics();
  const logAnalytics = useCallback(
    (event: string, data: Record<string, unknown>) => {
      try {
        logEvent(analytics, event, { iid: interview.id, ...data });
      } catch (error) {
        reportError(error);
      }
    },
    [analytics, interview.id],
  );

  const state = useMemo<SummaryState>(
    () => ({
      id: interview.id,
      cache: new Map<string, string>(),
      changed: new Map<string, boolean>(),
    }),
    [interview.id],
  );

  const { summary, regenerate, loading, error } = useSummary(interview);

  useReportError(error);

  const {
    field: { onChange, ...field },
    fieldState,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({ control, name: "summary.value" });

  useEffect(() => {
    if (!summary) {
      return;
    }

    const currentSummary = getValues("summary");
    if (currentSummary.name) {
      if (getFieldState("summary.value").isDirty) {
        state.cache.set(currentSummary.name, currentSummary.value);
      } else {
        state.cache.delete(currentSummary.name);
      }
    }

    resetField("summary", { defaultValue: summary });

    const dirtySummary = state.cache.get(summary.name);
    if (dirtySummary) {
      setValue("summary.value", dirtySummary, { shouldDirty: true });
    }

    logAnalytics("meeting_summary_regenerated", {
      name: summary.name,
      settings: summary.settings,
    });
  }, [
    logAnalytics,
    getFieldState,
    getValues,
    resetField,
    setValue,
    summary,
    state,
  ]);

  return (
    <>
      <Field
        label="Summary"
        validationState={fieldState.error ? "error" : undefined}
        validationMessage={fieldState.error?.message}
      >
        <Textarea
          {...field}
          rows={10}
          onChange={(event) => {
            const { name, settings } = getValues("summary");
            if (name && !state.changed.get(name)) {
              state.changed.set(name, true);
              logAnalytics("meeting_summary_changed", { name, settings });
            }
            onChange(event);
          }}
          disabled={
            (field.disabled ?? false) ||
            loading ||
            isSubmitting ||
            isSubmitSuccessful
          }
        />
      </Field>
      <Stack gap="s" verticalAlign="center" horizontal>
        <Button
          appearance="secondary"
          onClick={() => void regenerate()}
          disabled={loading || isSubmitting || isSubmitSuccessful}
        >
          {loading ? (
            <Spinner size="tiny" />
          ) : (
            <Stack gap="xs" verticalAlign="center" horizontal>
              <ArrowSyncRegular /> Regenerate
            </Stack>
          )}
        </Button>
        {summary && (
          <Span variant="caption1">
            Using model: <Span variant="caption1Stronger">{summary.name}</Span>
          </Span>
        )}
      </Stack>
    </>
  );
}
