import { useEffect } from "react";

import { makeStyles } from "@fluentui/react-components";

import { useDoc, useMissionControlDB } from "@aglocal/web/firebase";
import { useMissionControl } from "@aglocal/web/mission-control";

import Container from "@aglocal/web/components/Container";
import Stack from "@aglocal/web/components/Stack/Stack";

import { useTeams } from "@/teams";

import ErrorPage from "@/pages/Error";
import LoadingPage from "@/pages/Loading";

import Footer from "@/components/Footer";
import RecallStatus from "@/components/RecallStatus";
import TeamsBackground from "@/components/TeamsBackground";

import SidePanelAccordion from "./SidePanelAccordion";

const useStyles = makeStyles({
  container: {
    marginLeft: 0,
  },
  stack: {
    overflowX: "hidden",
  },
});

export interface SidePanelTabProps {
  iid: string;
}

export default function SidePanelTab({ iid }: SidePanelTabProps) {
  const styles = useStyles();
  const teams = useTeams();
  const api = useMissionControl();
  const db = useMissionControlDB();
  const [interview, loading, error] = useDoc(db.interviews.doc(iid));

  useEffect(() => {
    if (teams.inTeams) {
      void api.setMeetingStatus({ iId: iid, status: "Started" });
    }
  }, [api, teams.inTeams, iid]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage title="Error loading meeting" />;
  }

  if (!interview) {
    return <ErrorPage title="Meeting not found" />;
  }

  return (
    <TeamsBackground>
      <Container className={styles.container}>
        <Stack gap="m" className={styles.stack}>
          <SidePanelAccordion interview={interview} />
          <RecallStatus interview={interview} />
          <Footer />
        </Stack>
      </Container>
    </TeamsBackground>
  );
}
