import { Control, Controller, useFormState } from "react-hook-form";

import {
  Button,
  Combobox,
  Field,
  makeStyles,
  mergeClasses,
  Option,
  Radio,
  RadioGroup,
  shorthands,
  Spinner,
  tokens,
} from "@fluentui/react-components";

import { mediaQuery } from "@aglocal/web/helpers/responsive";
import useMatchMedia from "@aglocal/web/hooks/useMatchMedia";

import Container from "@aglocal/web/components/Container";
import Stack from "@aglocal/web/components/Stack/Stack";

import Copyright from "@/components/Copyright";

import { PostMeetingFormWithSummary } from "./form";

const MEETING_TYPE_OPTIONS = [
  { value: "G2", label: "G2" },
  { value: "STP", label: "Service Touchpoint" },
  { value: "Other", label: "Other" },
];

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderTop(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke1,
    ),
  },
  container: {
    paddingTop: tokens.spacingVerticalM,
    paddingBottom: tokens.spacingVerticalM,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    columnGap: tokens.spacingHorizontalM,
    rowGap: tokens.spacingVerticalM,
    [mediaQuery({ min: "xl" })]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    columnGap: tokens.spacingHorizontalM,
    rowGap: tokens.spacingVerticalM,
    [mediaQuery({ min: "xl" })]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  meetingTypeField: {
    whiteSpace: "nowrap",
    [mediaQuery({ min: "md" })]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      columnGap: tokens.spacingHorizontalS,
    },
  },
  meetingTypeInput: {
    "& > *": {
      whiteSpace: "nowrap",
    },
    [mediaQuery({ min: "md" })]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  button: {
    whiteSpace: "nowrap",
  },
});

export interface PostMeetingFooterProps {
  className?: string;
  control: Control<PostMeetingFormWithSummary>;
  drawerIsOpen: boolean;
  setDrawerIsOpen: (isOpen: boolean) => void;
}

export default function PostMeetingFooter({
  className,
  control,
  drawerIsOpen,
  setDrawerIsOpen,
}: PostMeetingFooterProps) {
  const styles = useStyles();
  const isSM = useMatchMedia({ max: "sm" });

  const { isSubmitting, isSubmitSuccessful } = useFormState({
    control,
    name: [],
  });

  const getSubmitButtonContent = () => {
    if (isSubmitting) {
      return (
        <Stack gap="xs" verticalAlign="center" horizontal>
          <Spinner size="tiny" />
          Updating Connected...
        </Stack>
      );
    }
    if (isSubmitSuccessful) {
      return "Connected Updated";
    }
    return "Update Connected";
  };

  return (
    <footer className={mergeClasses(styles.root, className)}>
      <Container className={styles.container}>
        <div className={styles.form}>
          <Controller
            control={control}
            name="meetingType"
            render={({
              field: { onChange, ...field },
              fieldState: { error },
              formState: { isSubmitting, isSubmitSuccessful },
            }) => (
              <Field
                label="Meeting Type"
                className={styles.meetingTypeField}
                orientation="vertical"
                validationState={error ? "error" : undefined}
                validationMessage={error?.message}
              >
                {isSM ? (
                  <Combobox
                    {...field}
                    onOptionSelect={(event, { optionValue }) => {
                      onChange(optionValue);
                    }}
                  >
                    {MEETING_TYPE_OPTIONS.map(({ value, label }) => (
                      <Option key={value} value={label}>
                        {label}
                      </Option>
                    ))}
                  </Combobox>
                ) : (
                  <RadioGroup
                    {...field}
                    onChange={(event, { value }) => {
                      onChange(value);
                    }}
                    layout="vertical"
                    className={styles.meetingTypeInput}
                    disabled={isSubmitting || isSubmitSuccessful}
                  >
                    {MEETING_TYPE_OPTIONS.map(({ value, label }) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>
                )}
              </Field>
            )}
          />
          <Button
            type="submit"
            className={styles.button}
            appearance="primary"
            disabled={isSubmitting || isSubmitSuccessful}
          >
            {getSubmitButtonContent()}
          </Button>
          <Button
            className={styles.button}
            appearance="secondary"
            onClick={() => {
              setDrawerIsOpen(!drawerIsOpen);
            }}
          >
            {drawerIsOpen ? "Hide Meeting Details" : "Show Meeting Details"}
          </Button>
        </div>
        <div style={{ textAlign: "center" }}>
          <Copyright />
        </div>
      </Container>
    </footer>
  );
}
