import { Schema } from "@effect/schema";
export const SecurityClearance = Schema.Literal("Confidential Or R", "Secret Or L", "Top Secret Or Q", "No");
export const FedClearanceType = Schema.Literal("CIA", "DHS", "DOD", "DOE", "FBI", "Military Branch", "Other");
export const PlacementType = Schema.Literal("any", "contract", "contract-to-hire", "direct-placement");
export const ScheduleType = Schema.Literal("full-time", "part-time", "both");
export const WorkplaceType = Schema.Literal("100% Remote", "Hybrid", "Onsite");
export const Shift = Schema.Literal("First", "Second", "Third", "Weekend");
export const WorkPreferences = Schema.Struct({
    placementType: Schema.optional(Schema.Array(PlacementType)),
    schedule: Schema.optional(ScheduleType),
    workplaceType: Schema.optional(Schema.Array(WorkplaceType)),
    shift: Schema.optional(Schema.Array(Shift)),
    dateAvailable: Schema.optional(Schema.String),
});
export const PayRateFrequency = Schema.Literal("Hourly", "Daily", "Weekly", "Monthly");
export const PayRate = Schema.Struct({
    low: Schema.Number,
    high: Schema.optional(Schema.Number),
    frequency: PayRateFrequency,
});
export const Currency = Schema.Literal("USD - U.S Dollar", "CAD - Canadian Dollar", "EUR - Euro", "GBP - British Pound", "ZAR - South African Rand", "INR - Indian Rupee", "AUD - Australian Dollar", "RUB - Russian Ruble", "SEK - Swedish Krona", "JPY - Japanese Yen", "CHF - Swiss Franc", "AED - Emirati Dirham", "HKD - Hong Kong Dollar", "SGD - Singapore Dollar", "CNY - Chinese Yuan Renminbi", "DKK - Danish Krone", "MXN - Mexican Peso", "BRL - Brazilian Real", "NZD - New Zealand Dollar", "NOK - Norwegian Krone", "CZK - Czech Koruna", "HUF - Hungarian Forint", "PLN - Polish Zloty", "MYR - Malaysian Ringgit", "PHP - Philippines Peso");
export const Salary = Schema.Struct({
    low: Schema.Number,
    high: Schema.optional(Schema.Number),
    currency: Currency,
});
export const AdditionalCompensation = Schema.Struct({
    bonusAmount: Schema.optional(Schema.Number),
    bonusPercentage: Schema.optional(Schema.Number),
    additionalCompensation: Schema.optional(Schema.Number),
});
export const CompensationPreferences = Schema.Struct({
    payRate: Schema.optional(PayRate),
    salary: Schema.optional(Salary),
    additionalCompensation: Schema.optional(AdditionalCompensation),
});
export const PreferredLocation = Schema.Struct({
    city: Schema.optional(Schema.String),
    state: Schema.optional(Schema.String),
    country: Schema.optional(Schema.String),
});
export const YesNoUnknown = Schema.Literal("Yes", "No", "Unknown");
export const CommutePreferenceUnit = Schema.Literal("minutes", "miles", "kilometers");
export const CommutePreference = Schema.Struct({
    value: Schema.Number,
    unit: CommutePreferenceUnit,
});
export const LocationPreferences = Schema.Struct({
    willingToRelocate: Schema.optional(YesNoUnknown),
    nationalOpportunities: Schema.optional(YesNoUnknown),
    reliableTransportation: Schema.optional(YesNoUnknown),
    commutePreference: Schema.optional(CommutePreference),
    countriesLegallyAuthorizedToWork: Schema.optional(Schema.Array(Schema.String)),
    preferredLocations: Schema.optional(Schema.Array(PreferredLocation)),
    comments: Schema.optional(Schema.String),
});
export const TalentMeetingDetails = Schema.Struct({
    location: Schema.optional(Schema.String),
    phoneNumber: Schema.optional(Schema.String),
    email: Schema.optional(Schema.String),
    currentCompany: Schema.optional(Schema.String),
    jobTitle: Schema.optional(Schema.String),
    skills: Schema.optional(Schema.Array(Schema.String)),
    skillsComments: Schema.optional(Schema.String),
    securityClearance: Schema.optional(SecurityClearance),
    fedClearanceType: Schema.optional(FedClearanceType),
    languagesSpoken: Schema.optional(Schema.Array(Schema.String)),
    goalsAndInterests: Schema.optional(Schema.String),
    workPreferences: Schema.optional(WorkPreferences),
    compensationPreferences: Schema.optional(CompensationPreferences),
    locationPreferences: Schema.optional(LocationPreferences),
});
