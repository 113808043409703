import type { Interview } from "@aglocal/schema/Interview";

import Callout from "@aglocal/web/components/Callout";
import { Heading, Text } from "@aglocal/web/components/Typography";
import Container from "@aglocal/web/components/Container";

import ConnectedDetails from "@/components/ConnectedDetails";

export interface OutsideMeetingTabProps {
  interview: Interview;
}

export default function OutsideMeetingTab({
  interview: { interviewee },
}: OutsideMeetingTabProps) {
  return (
    <Container>
      <Heading>
        Prepare for talent conversation
        {interviewee?.displayName && ` with ${interviewee.displayName}`}
      </Heading>

      <Text size={500}>
        A place to research what we already know about{" "}
        {interviewee?.displayName ?? "the talent"} and suggest questions and
        conversations ideas which relate to us better knowing them and how we
        can help them with their career goals.
      </Text>

      <Heading as="h2">Connected Information</Heading>
      {interviewee?.connectedDetails ? (
        <ConnectedDetails details={interviewee.connectedDetails} />
      ) : (
        <Callout intent="warning" title="Connected Details not found" />
      )}
    </Container>
  );
}
