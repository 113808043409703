import { Schema } from "@effect/schema";
import { CommutePreference, FedClearanceType, PayRate, PlacementType, Salary, ScheduleType, SecurityClearance, Shift, WorkplaceType, YesNoUnknown, } from "./TalentMeetingDetails.js";
export const AdditionalDetails = Schema.Struct({
    securityClearance: SecurityClearance,
    fedClearanceType: FedClearanceType,
    dateAvailable: Schema.String,
    desiredPlacementType: Schema.Array(PlacementType),
    desiredSchedule: ScheduleType,
    desiredWorkplaceType: Schema.Array(WorkplaceType),
    desiredShift: Schema.Array(Shift),
    desiredPayRate: PayRate,
    desiredSalary: Salary,
    willingToRelocate: YesNoUnknown,
    nationalOpportunities: YesNoUnknown,
    reliableTransportation: YesNoUnknown,
    commutePreference: CommutePreference,
});
export const PostMeetingForm = Schema.Struct({
    // Hidden Fields
    id: Schema.String,
    // Form Fields
    meetingType: Schema.Literal("G2", "STP", "Other"),
    jobTitle: Schema.String.pipe(Schema.nonEmptyString({ message: () => "Please enter a job title" })),
    currentCompany: Schema.String,
    notes: Schema.String,
    skills: Schema.Array(Schema.String),
    skillsComments: Schema.String,
    goalsAndInterests: Schema.String,
    locationComments: Schema.String,
    // Additional Details
    additionalDetails: Schema.partial(AdditionalDetails),
});
