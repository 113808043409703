import { Schema } from "@effect/schema";
export const TranscriptWord = Schema.Struct({
    text: Schema.String,
    start_timestamp: Schema.Number,
    end_timestamp: Schema.Number,
    confidence: Schema.NullishOr(Schema.Number),
    language: Schema.NullishOr(Schema.String),
});
export const TranscriptItem = Schema.Struct({
    speaker: Schema.NullishOr(Schema.String),
    speaker_id: Schema.NullishOr(Schema.Number),
    words: Schema.optional(Schema.Array(TranscriptWord)),
    language: Schema.NullishOr(Schema.String),
});
export const Transcript = Schema.Array(TranscriptItem);
export const RealtimeTranscript = Schema.Struct({
    transcript: Schema.optional(Transcript),
});
