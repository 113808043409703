import equal from "fast-deep-equal";
import useDedupe from "@aglocal/web/hooks/useDedupe";
import { useDocSnapshot, useMissionControlDB } from "@aglocal/web/firebase";
export default function useTranscript(id, interview) {
    const db = useMissionControlDB();
    const { snapshot, loading, error } = useDocSnapshot(db.rrts.doc(id));
    const transcript = useDedupe(snapshot?.data()?.transcript ?? interview?.transcript, equal);
    if (transcript != null) {
        return { transcript, loading: false };
    }
    return { loading, error };
}
