import { Schema } from "@effect/schema";
import { EmailRecipient } from "./EmailRecipient.js";
import { MSGraphSync } from "./MSGraphSync.js";
import { WorkingHours } from "./MeetingTimes.js";
import { Transcript } from "./Transcript.js";
import { RecallStatus } from "./Recall.js";
export const TalentDetails = Schema.Struct({
    contactId: Schema.String,
    accountId: Schema.String,
    displayName: Schema.String,
    candidateStatus: Schema.optional(Schema.String),
    firstName: Schema.optional(Schema.String),
    g2Date: Schema.optional(Schema.String),
    g2Notes: Schema.optional(Schema.String),
    jobTitle: Schema.NullishOr(Schema.String),
    lastModifiedDate: Schema.optional(Schema.String),
    lastName: Schema.optional(Schema.String),
    lastUpdatedInFirestore: Schema.optional(Schema.String),
    latestResumeDate: Schema.NullishOr(Schema.String),
    latestResumeId: Schema.NullishOr(Schema.String),
    mailingCity: Schema.NullishOr(Schema.String),
    mailingCountry: Schema.NullishOr(Schema.String),
    mailingState: Schema.NullishOr(Schema.String),
    skills: Schema.optional(Schema.Array(Schema.String)),
    source: Schema.optional(Schema.Literal("sfdc", "snowflake")),
    type: Schema.optional(Schema.String),
});
export const Person = Schema.Struct({
    displayName: Schema.String,
    persona: Schema.NullishOr(Schema.String),
});
export const MeetingStatus = Schema.Literal("In-Progress", "Preparing", "Complete", "GENERIC_LINK");
export const Talent = Schema.Struct({
    ...Person.fields,
    uId: Schema.NullOr(Schema.String).annotations({
        description: "Could be upn, email address, etc.",
    }),
    accountId: Schema.NullishOr(Schema.String),
    phoneNumber: Schema.NullishOr(Schema.String),
    connectedId: Schema.NullishOr(Schema.String),
    originalPhoneNumber: Schema.NullishOr(Schema.String),
    originalEmail: Schema.NullishOr(Schema.String),
    confirmedEmail: Schema.NullishOr(Schema.String),
    confirmedPhoneNumber: Schema.NullishOr(Schema.String),
    confirmedPhoneCountry: Schema.NullishOr(Schema.String),
    smsOptIn: Schema.NullishOr(Schema.Boolean),
    smsOptOut: Schema.NullishOr(Schema.Boolean),
    brand: Schema.NullishOr(Schema.String),
    locale: Schema.NullishOr(Schema.String),
    connectedDetails: Schema.NullishOr(TalentDetails),
});
export const Recruiter = Schema.Struct({
    ...Person.fields,
    uId: Schema.String.annotations({
        description: "Could be upn, email address, etc.",
    }),
    aADID: Schema.String.annotations({
        description: "Azure active directory user id (from graph)",
    }),
    city: Schema.NullishOr(Schema.String),
    companyName: Schema.NullishOr(Schema.String),
    country: Schema.NullishOr(Schema.String),
    givenName: Schema.NullishOr(Schema.String),
    jobTitle: Schema.NullishOr(Schema.String),
    officeLocation: Schema.NullishOr(Schema.String),
    phoneNumber: Schema.NullishOr(Schema.String),
    surname: Schema.NullishOr(Schema.String),
    userId: Schema.NullishOr(Schema.String),
});
export const Recall = Schema.Struct({
    botId: Schema.String,
    created: Schema.String,
    status: RecallStatus,
    transcribeService: Schema.optional(Schema.String),
});
export const TimeSlot = Schema.Struct({
    startTimeDate: Schema.Date,
    endTimeDate: Schema.Date,
});
export const Interview = Schema.Struct({
    id: Schema.String,
    arrangeSource: Schema.optional(Schema.String),
    azureCommunicationsServiceUID: Schema.optional(Schema.String),
    bbcRecipients: Schema.optional(Schema.Array(EmailRecipient)),
    cancelled: Schema.optional(Schema.Boolean),
    detectedLanguage: Schema.optional(Schema.String),
    emailSent: Schema.optional(Schema.Boolean),
    endTimeDate: Schema.optional(Schema.String),
    eventId: Schema.optional(Schema.String),
    inMeetingNotes: Schema.optional(Schema.String),
    interviewee: Schema.optional(Talent),
    interviewer: Schema.optional(Recruiter),
    interviewerJoined: Schema.optional(Schema.String),
    isGenericLink: Schema.optional(Schema.Boolean),
    joinWebUrl: Schema.optional(Schema.String),
    lastModifiedDate: Schema.optional(Schema.String),
    localTimeZone: Schema.optional(Schema.String),
    meetingDuration: Schema.optional(Schema.Literal("15", "30", "45", "60")),
    meetingId: Schema.optional(Schema.String),
    meetingStatus: Schema.optional(MeetingStatus),
    messageToCandidate: Schema.NullishOr(Schema.String),
    msGraphSync: Schema.optional(MSGraphSync),
    onlineMeetingId: Schema.optional(Schema.String),
    recall: Schema.optional(Recall),
    resumeParsedDate: Schema.optional(Schema.String),
    resumeParsedResult: Schema.optional(Schema.Literal("unparsed", "parsedValidResume")),
    resumeUploadDate: Schema.optional(Schema.String),
    resumeUploaded: Schema.optional(Schema.Boolean),
    selectedLanguage: Schema.optional(Schema.String),
    sendEmail: Schema.optional(Schema.Boolean),
    startTimeDate: Schema.optional(Schema.String),
    status: Schema.optional(Schema.Literal("Started")),
    tabId: Schema.optional(Schema.String),
    timeSlot: Schema.optional(TimeSlot),
    transcript: Schema.optional(Transcript),
    useTalentClient: Schema.optional(Schema.Boolean),
    workingHours: Schema.optional(WorkingHours),
});
