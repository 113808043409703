import { Schema } from "@effect/schema";
export const DateTimeTimeZone = Schema.Struct({
    dateTime: Schema.String,
    timeZone: Schema.String,
});
export const OnlineMeeting = Schema.Struct({
    id: Schema.String,
    subscriptionId: Schema.optional(Schema.String),
    when: Schema.Struct({
        startTimeDate: DateTimeTimeZone,
        endTimeDate: DateTimeTimeZone,
    }),
    joinWebUrl: Schema.String,
    chatThreadId: Schema.String,
    lastUpdated: DateTimeTimeZone,
});
export const ChatSystemStatus = Schema.Struct({
    meetingId: Schema.NonEmptyString,
    status: Schema.Literal("Preparing", "In-Progress", "Complete", "Unknown", "Error"),
    subscriptionId: Schema.optional(Schema.String),
    message: Schema.optional(Schema.String),
    needsUpdating: Schema.optional(Schema.Boolean),
    lastUpdated: DateTimeTimeZone,
});
export const MSGraphSync = Schema.Struct({
    onlineMeeting: Schema.optional(OnlineMeeting),
    chatSystemStatus: Schema.optional(ChatSystemStatus),
});
