import { Control } from "react-hook-form";

import { PostMeetingFormWithSummary } from "../form";

import TextField from "./TextField";

export interface NotesFieldProps {
  control: Control<PostMeetingFormWithSummary>;
}

export default function NotesField({ control }: NotesFieldProps) {
  return <TextField name="notes" label="Notes" control={control} />;
}
