import {
  Control,
  FieldPathByValue,
  FieldValues,
  useController,
} from "react-hook-form";

import { Field, FieldProps, Textarea } from "@fluentui/react-components";

export interface StringFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends FieldProps {
  name: TName;
  control: Control<TFieldValues>;
  rows?: number;
}

export default function StringField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>({
  name,
  control,
  rows = 5,
  ...props
}: StringFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({
    name,
    control,
  });

  return (
    <Field
      validationState={error ? "error" : undefined}
      validationMessage={error?.message}
      {...props}
    >
      <Textarea
        {...field}
        rows={rows}
        disabled={isSubmitting || isSubmitSuccessful}
      />
    </Field>
  );
}
