import { useEffect, useMemo } from "react";
import { Control } from "react-hook-form";

import type { Interview } from "@aglocal/schema/Interview";
import type { LLMNEREntity } from "@aglocal/schema/LLM-NER";
import type { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";

import titleCase from "@aglocal/web/helpers/titleCase";
import { Span } from "@aglocal/web/components/Typography";

import getJobTitle from "@/helpers/getJobTitle";
import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormWithSummary } from "../form";

import StringField from "./StringField";

export interface TitleFieldProps {
  interview: Interview;
  entities: readonly LLMNEREntity[];
  details: Readonly<TalentMeetingDetails> | undefined;
  control: Control<PostMeetingFormWithSummary>;
  setDefault: UseFormSetDefault<PostMeetingFormWithSummary>;
}

export default function TitleField({
  interview,
  entities,
  details,
  control,
  setDefault,
}: TitleFieldProps) {
  const currentJobTitle = getJobTitle(interview);

  const defaultJobTitle = useMemo(() => {
    if (details?.jobTitle) return details.jobTitle;
    const entity = entities.find(({ label }) => label === "TITLE");
    return entity ? titleCase(entity.text) : (currentJobTitle ?? "");
  }, [details?.jobTitle, entities, currentJobTitle]);

  useEffect(() => {
    setDefault("jobTitle", defaultJobTitle);
  }, [defaultJobTitle, setDefault]);

  return (
    <StringField
      name="jobTitle"
      label="Job Title"
      control={control}
      hint={
        currentJobTitle && (
          <>
            Current Title:{" "}
            <Span size={200} weight="bold">
              {currentJobTitle}
            </Span>
          </>
        )
      }
    />
  );
}
