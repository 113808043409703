import { useEffect } from "react";
import { Control } from "react-hook-form";

import type { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";

import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormWithSummary } from "../form";

import TextField from "./TextField";

export interface GoalsAndInterestsFieldProps {
  details: Readonly<TalentMeetingDetails> | undefined;
  control: Control<PostMeetingFormWithSummary>;
  setDefault: UseFormSetDefault<PostMeetingFormWithSummary>;
}

export default function GoalsAndInterestsField({
  details,
  control,
  setDefault,
}: GoalsAndInterestsFieldProps) {
  const defaultValue = details?.goalsAndInterests ?? "";

  useEffect(() => {
    setDefault("goalsAndInterests", defaultValue);
  }, [defaultValue, setDefault]);

  return (
    <TextField
      name="goalsAndInterests"
      label="Goals & Interests"
      control={control}
    />
  );
}
