import { useEffect } from "react";
import { Control } from "react-hook-form";

import type { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";

import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormWithSummary } from "../form";

import TextField from "./TextField";

export interface SkillsCommentsFieldProps {
  details: Readonly<TalentMeetingDetails> | undefined;
  control: Control<PostMeetingFormWithSummary>;
  setDefault: UseFormSetDefault<PostMeetingFormWithSummary>;
}

export default function SkillsCommentsField({
  details,
  control,
  setDefault,
}: SkillsCommentsFieldProps) {
  const defaultValue = details?.skillsComments ?? "";

  useEffect(() => {
    setDefault("skillsComments", defaultValue);
  }, [defaultValue, setDefault]);

  return (
    <TextField name="skillsComments" label="Skill Comments" control={control} />
  );
}
