import { Accordion, makeStyles, tokens } from "@fluentui/react-components";

import type { Interview } from "@aglocal/schema/Interview";

import ActionItemsAccordionItem from "./ActionItemsAccordionItem";
import NotesAccordionItem from "./NotesAccordionItem";
import SkillsAccordionItem from "./SkillsAccordionItem";
import LanguageAccordionItem from "./LanguageAccordionItem";

const useStyles = makeStyles({
  root: {
    marginLeft: `calc(-1 * ${tokens.spacingHorizontalM})`,
    marginRight: `calc(-1 * ${tokens.spacingHorizontalM})`,
  },
});

export interface SidePanelAccordionProps {
  interview: Interview;
}

export default function SidePanelAccordion({
  interview,
}: SidePanelAccordionProps) {
  const styles = useStyles();
  return (
    <Accordion
      className={styles.root}
      defaultOpenItems={["action-items", "skills", "notes"]}
      multiple
    >
      <ActionItemsAccordionItem name="action-items" interview={interview} />
      <SkillsAccordionItem name="skills" interview={interview} />
      <NotesAccordionItem name="notes" interview={interview} />
      <LanguageAccordionItem name="language" interview={interview} />
    </Accordion>
  );
}
