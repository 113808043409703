import { useEffect } from "react";
import { Control } from "react-hook-form";

import type { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";

import { Span } from "@aglocal/web/components/Typography";

import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormWithSummary } from "../form";

import StringField from "./StringField";

export interface CompanyFieldProps {
  // TODO: get current company name from connected details
  currentValue?: string | null;
  details: Readonly<TalentMeetingDetails> | undefined;
  control: Control<PostMeetingFormWithSummary>;
  setDefault: UseFormSetDefault<PostMeetingFormWithSummary>;
}

export default function CompanyField({
  currentValue,
  details,
  control,
  setDefault,
}: CompanyFieldProps) {
  const defaultValue = details?.currentCompany ?? "";

  useEffect(() => {
    setDefault("currentCompany", defaultValue);
  }, [defaultValue, setDefault]);

  return (
    <StringField
      name="currentCompany"
      label="Current Company"
      control={control}
      hint={
        currentValue && (
          <>
            Current Company:{" "}
            <Span size={200} weight="bold">
              {currentValue}
            </Span>
          </>
        )
      }
    />
  );
}
