import { ReactNode, useEffect, useMemo } from "react";
import { FormattedDate, FormattedList, FormattedNumber } from "react-intl";
import { Control, useController } from "react-hook-form";

import { Checkbox, Label } from "@fluentui/react-components";

import type { AdditionalDetails } from "@aglocal/schema/PostMeetingForm";
import type { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";

import Stack from "@aglocal/web/components/Stack";

import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormWithSummary } from "../form";

interface DetailOption {
  name: keyof AdditionalDetails;
  label: ReactNode;
  value: unknown;
}

export interface DetailsFieldProps {
  details: Readonly<TalentMeetingDetails> | undefined;
  control: Control<PostMeetingFormWithSummary>;
  setDefault: UseFormSetDefault<PostMeetingFormWithSummary>;
}

export default function DetailsField({
  details,
  control,
  setDefault,
}: DetailsFieldProps) {
  const {
    field,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({
    control,
    name: "additionalDetails",
  });

  const options = useMemo<DetailOption[]>(() => {
    const result: DetailOption[] = [];

    if (!details) {
      return result;
    }

    if (details.securityClearance) {
      result.push({
        name: "securityClearance",
        label: (
          <>
            Security clearance of <strong>{details.securityClearance}</strong>.
          </>
        ),
        value: details.securityClearance,
      });
    }

    if (details.fedClearanceType) {
      result.push({
        name: "fedClearanceType",
        label: (
          <>
            Federal clearance type of{" "}
            <strong>{details.fedClearanceType}</strong>.
          </>
        ),
        value: details.fedClearanceType,
      });
    }

    if (details.workPreferences?.dateAvailable) {
      result.push({
        name: "dateAvailable",
        label: (
          <>
            Date available of{" "}
            <strong>
              <FormattedDate
                value={details.workPreferences.dateAvailable}
                timeZone="UTC"
              />
            </strong>
            .
          </>
        ),
        value: details.workPreferences.dateAvailable,
      });
    }

    if (details.workPreferences?.placementType?.length) {
      result.push({
        name: "desiredPlacementType",
        label: (
          <>
            Placement type of{" "}
            <strong>
              <FormattedList
                type="disjunction"
                value={details.workPreferences.placementType}
              />
            </strong>
            .
          </>
        ),
        value: details.workPreferences.placementType,
      });
    }

    if (details.workPreferences?.schedule) {
      result.push({
        name: "desiredSchedule",
        label: (
          <>
            Desired schedule of{" "}
            <strong>{details.workPreferences.schedule}</strong>.
          </>
        ),
        value: details.workPreferences.schedule,
      });
    }

    if (details.workPreferences?.workplaceType?.length) {
      result.push({
        name: "desiredWorkplaceType",
        label: (
          <>
            Desired workplace type of{" "}
            <strong>
              <FormattedList
                type="disjunction"
                value={details.workPreferences.workplaceType}
              />
            </strong>
            .
          </>
        ),
        value: details.workPreferences.workplaceType,
      });
    }

    if (details.workPreferences?.shift?.length) {
      result.push({
        name: "desiredShift",
        label: (
          <>
            Desired shift of{" "}
            <strong>
              <FormattedList
                type="disjunction"
                value={details.workPreferences.shift}
              />
            </strong>
            .
          </>
        ),
        value: details.workPreferences.shift,
      });
    }

    const currency =
      details.compensationPreferences?.salary?.currency.split(" - ")[0] ??
      "USD";

    if (details.compensationPreferences?.payRate) {
      result.push({
        name: "desiredPayRate",
        label: (
          <>
            Compensation pay rate of{" "}
            <strong>
              <FormattedNumber
                style="currency"
                value={details.compensationPreferences.payRate.low}
                currency={currency}
              />
              {details.compensationPreferences.payRate.high != null && (
                <>
                  {" - "}
                  <FormattedNumber
                    style="currency"
                    value={details.compensationPreferences.payRate.high}
                    currency={currency}
                  />
                </>
              )}{" "}
              {details.compensationPreferences.payRate.frequency}
            </strong>
            .
          </>
        ),
        value: details.compensationPreferences.payRate,
      });
    }

    if (details.compensationPreferences?.salary) {
      const currency =
        details.compensationPreferences.salary.currency.split(" - ")[0];

      result.push({
        name: "desiredSalary",
        label: (
          <>
            Compensation salary of{" "}
            <strong>
              <FormattedNumber
                style="currency"
                value={details.compensationPreferences.salary.low}
                currency={currency}
                maximumFractionDigits={0}
              />
              {details.compensationPreferences.salary.high != null && (
                <>
                  {" - "}
                  <FormattedNumber
                    style="currency"
                    value={details.compensationPreferences.salary.high}
                    currency={currency}
                    maximumFractionDigits={0}
                  />
                </>
              )}{" "}
              {currency}
            </strong>
            .
          </>
        ),
        value: details.compensationPreferences.salary,
      });
    }

    if (details.locationPreferences?.willingToRelocate) {
      result.push({
        name: "willingToRelocate",
        label: (
          <>
            <strong>
              {details.locationPreferences.willingToRelocate == "Yes" &&
                "Willing"}
              {details.locationPreferences.willingToRelocate == "No" &&
                "Not willing"}
              {details.locationPreferences.willingToRelocate == "Unknown" &&
                "Unknown willing"}
            </strong>{" "}
            to relocate.
          </>
        ),
        value: details.locationPreferences.willingToRelocate,
      });
    }

    if (details.locationPreferences?.nationalOpportunities) {
      result.push({
        name: "nationalOpportunities",
        label: (
          <>
            <strong>
              {details.locationPreferences.nationalOpportunities == "Yes" &&
                "Open to"}
              {details.locationPreferences.nationalOpportunities == "No" &&
                "Not open to"}
              {details.locationPreferences.nationalOpportunities == "Unknown" &&
                "Unknown preference for"}
            </strong>{" "}
            national opportunities.
          </>
        ),
        value: details.locationPreferences.nationalOpportunities,
      });
    }

    if (details.locationPreferences?.reliableTransportation) {
      result.push({
        name: "reliableTransportation",
        label: (
          <>
            <strong>
              {details.locationPreferences.reliableTransportation == "Yes" &&
                "Has"}
              {details.locationPreferences.reliableTransportation == "No" &&
                "Does not have"}
              {details.locationPreferences.reliableTransportation ==
                "Unknown" && "Unknown"}
            </strong>{" "}
            reliable transportation.
          </>
        ),
        value: details.locationPreferences.reliableTransportation,
      });
    }

    if (details.locationPreferences?.commutePreference) {
      result.push({
        name: "commutePreference",
        label: (
          <>
            Commute preference of{" "}
            <strong>
              <FormattedNumber
                value={details.locationPreferences.commutePreference.value}
              />{" "}
              {details.locationPreferences.commutePreference.unit}
            </strong>
            .
          </>
        ),
        value: details.locationPreferences.commutePreference,
      });
    }

    return result;
  }, [details]);

  useEffect(() => {
    setDefault(
      "additionalDetails",
      Object.fromEntries(options.map(({ name, value }) => [name, value])),
    );
  }, [setDefault, options]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Stack gap="none">
      <Label>Additional details found</Label>
      {options.map(({ name, label, value }) => (
        <Checkbox
          key={name}
          label={{
            children: label,
          }}
          checked={field.value[name] != null}
          disabled={isSubmitting || isSubmitSuccessful}
          onChange={(event) => {
            field.onChange({
              ...field.value,
              [name]: event.target.checked ? value : undefined,
            });
          }}
        />
      ))}
    </Stack>
  );
}
