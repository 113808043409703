import { useReducer, useState } from "react";
import asError from "@aglocal/web/helpers/asError";
import useAbort from "@aglocal/web/hooks/useAbort";
export default function useAsync(callback, deps = []) {
    const [refreshCount, refresh] = useReducer((count) => count + 1, 0);
    const [state, setState] = useState({ loading: true });
    useAbort((signal) => {
        setState({ loading: true });
        callback(signal).then((result) => {
            if (signal.aborted)
                return;
            setState({ loading: false, result });
        }, (error) => {
            if (signal.aborted)
                return;
            setState({ loading: false, error: asError(error) });
        });
    }, [refreshCount, ...deps]);
    return { ...state, refresh };
}
