import { Schema } from "@effect/schema";
export const JSONEncoding = {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
};
export class ResponseError extends Error {
    constructor(response) {
        super(response.statusText);
        Object.defineProperty(this, "response", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: response
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = "ResponseError";
        this.status = response.status;
    }
}
export default class HttpAPI {
    constructor(auth) {
        Object.defineProperty(this, "auth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: auth
        });
    }
    async request({ schema, method, url, data, options, encoding = JSONEncoding, retries = 1, }) {
        const token = await this.auth.getToken(options?.signal);
        const headers = { Authorization: `Bearer ${token}` };
        if (data !== undefined) {
            headers["Content-Type"] = "application/json";
        }
        const request = {
            method,
            headers,
            signal: options?.signal,
            body: data !== undefined ? encoding.serialize(data) : undefined,
        };
        const response = await fetch(url, request);
        // Our token has expired, delete it and try again
        if (response.status === 401) {
            await this.auth.reset();
            if (retries > 0) {
                return await this.request({
                    schema,
                    method,
                    url,
                    data,
                    options,
                    encoding,
                    retries: retries - 1,
                });
            }
        }
        if (!response.ok) {
            throw new ResponseError(response);
        }
        const text = await response.text();
        const body = encoding.deserialize(text);
        const decode = Schema.decodeUnknownSync(schema);
        return decode(body, { errors: "all", onExcessProperty: "ignore" });
    }
}
