import type { Recruiter } from "@aglocal/schema/Interview";

import { useAuth } from "@/auth";
import getUserId from "@/helpers/getUserId";

export default function useIsCurrentUser(recruiter: Recruiter | undefined) {
  const auth = useAuth();

  if (!recruiter?.aADID) {
    return false;
  }

  return getUserId(auth) === recruiter.aADID;
}
