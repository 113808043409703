import { FrameContexts } from "@microsoft/teams-js";

import useIId from "@/hooks/useIId";
import { useTeams } from "@/teams";

import ErrorPage from "@/pages/Error";
import LoadingPage from "@/pages/Loading";

import FullPageApp from "./FullPageApp";
import SidePanelTab from "./SidePanelTab";

export default function TalentAssistantApp() {
  const teams = useTeams();
  const iid = useIId();

  if (!iid) {
    return <ErrorPage title="No interview ID provided" />;
  }

  if (teams.loading) {
    return <LoadingPage />;
  }

  switch (teams.inTeams && teams.context.page.frameContext) {
    case FrameContexts.sidePanel:
      return <SidePanelTab iid={iid} />;
    default:
      return <FullPageApp iid={iid} />;
  }
}
