import { IntlProvider } from "react-intl";
import { RouterProvider } from "react-router-dom";

import { AuthProvider } from "@/auth";
import { TeamsProvider } from "@/teams";
import router from "@/router";
import { logIntlError } from "@/intl";

import "@/styles/index.scss";

export default function App() {
  return (
    <IntlProvider
      locale={navigator.language}
      defaultLocale="en"
      onError={logIntlError}
    >
      <TeamsProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </TeamsProvider>
    </IntlProvider>
  );
}
