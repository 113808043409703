import { useMemo } from "react";
import { MissionControl } from "@aglocal/schema/firestore";
import { useFirestore } from "./context";
import createDB from "./db";
export * from "./context";
export * from "./firestore";
export { default as FirebaseProvider } from "./FirebaseProvider";
export function useMissionControlDB() {
    const firestore = useFirestore();
    return useMemo(() => createDB(firestore, MissionControl), [firestore]);
}
