import { Schema } from "@effect/schema";
export const LLMNERLocation = Schema.Struct({
    start: Schema.Number,
    end: Schema.Number,
});
export const LLMNEREntity = Schema.Struct({
    text: Schema.String,
    label: Schema.String,
    natural: Schema.String,
});
export const LLMNERModelOutput = Schema.Struct({
    entities: Schema.Array(LLMNEREntity),
});
