import { useEffect } from "react";
export default function useAbort(callback, deps) {
    useEffect(() => {
        const controller = new AbortController();
        callback(controller.signal);
        return () => {
            controller.abort();
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps);
}
