import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  InlineDrawer,
} from "@fluentui/react-components";

import { Dismiss24Regular } from "@fluentui/react-icons";

import useMatchMedia from "@aglocal/web/hooks/useMatchMedia";

import SidePanelAccordion, {
  type SidePanelAccordionProps,
} from "./PostMeetingAccordion";

export interface PostMeetingDrawerProps extends SidePanelAccordionProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export default function PostMeetingDrawer({
  isOpen,
  onOpenChange,
  ...props
}: PostMeetingDrawerProps) {
  const isXXL = useMatchMedia({ min: "xl" });
  return (
    <InlineDrawer
      as="aside"
      position="end"
      size={isXXL ? "medium" : "full"}
      open={isOpen}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => {
                onOpenChange(false);
              }}
            />
          }
        >
          Details Gathered During Meeting
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        <SidePanelAccordion {...props} />
      </DrawerBody>
    </InlineDrawer>
  );
}
