import { Schema } from "@effect/schema";
export const DarkquellSkillType = Schema.Struct({
    id: Schema.String,
    name: Schema.String,
});
export const DarkquellSkillTag = Schema.Struct({
    key: Schema.String,
    value: Schema.String,
});
export const DarkquellSkillCategory = Schema.Struct({
    id: Schema.Number,
    name: Schema.String,
});
export const DarkquellSkill = Schema.Struct({
    id: Schema.String,
    name: Schema.String,
    type: DarkquellSkillType,
    tags: Schema.Array(DarkquellSkillTag),
    infoUrl: Schema.String,
    isSoftware: Schema.Boolean,
    isLanguage: Schema.Boolean,
    description: Schema.NullishOr(Schema.String),
    descriptionSource: Schema.NullishOr(Schema.String),
    category: Schema.NullishOr(DarkquellSkillCategory),
    subcategory: Schema.NullishOr(DarkquellSkillCategory),
});
export const DarkquellSkillSource = Schema.Struct({
    text: Schema.String,
    start: Schema.Number,
    end: Schema.Number,
    confidence: Schema.Number,
});
export const DarkquellInferredSkill = Schema.Struct({
    skill: DarkquellSkill,
    maxConfidence: Schema.propertySignature(Schema.Number).pipe(Schema.fromKey("max_confidence")),
    sources: Schema.Array(DarkquellSkillSource),
});
export const DarkquellOutput = Schema.Struct({
    skills: Schema.NullishOr(Schema.Array(DarkquellInferredSkill)),
});
