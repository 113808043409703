import { Schema } from "@effect/schema";
export const TeamsConversation = Schema.Struct({
    activityId: Schema.optional(Schema.String),
    bot: Schema.Struct({
        id: Schema.String,
        name: Schema.String,
    }),
    serviceUrl: Schema.String,
    user: Schema.Struct({
        id: Schema.String,
        aadObjectId: Schema.optional(Schema.String),
    }),
    conversation: Schema.Struct({
        id: Schema.String,
        isGroup: Schema.Boolean,
        tenantId: Schema.optional(Schema.String),
        conversationType: Schema.optional(Schema.Literal("groupChat", "channel", "personal")),
    }),
    channelId: Schema.Literal("webchat", "msteams"),
});
