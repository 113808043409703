import "@/error-reporting";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "@/App";
import invariant from "@aglocal/web/helpers/invariant";

const container = document.getElementById("root");
invariant(container != null, "No root element found");

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
