import { Schema } from "@effect/schema";
export const DayOfWeek = Schema.Literal("sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday");
export const TimeZone = Schema.Struct({
    name: Schema.String,
});
export const WorkingHours = Schema.Struct({
    // The days of the week on which the user works.
    daysOfWeek: Schema.Array(DayOfWeek),
    // The time of the day that the user starts working.
    startTime: Schema.String,
    // The time of the day that the user stops working.
    endTime: Schema.String,
    // The time zone to which the working hours apply.
    timeZone: TimeZone,
});
export const TimeSlot = Schema.Struct({
    start: Schema.String,
    end: Schema.String,
});
export const PersonConfig = Schema.Struct({
    name: Schema.optional(Schema.String),
    email: Schema.optional(Schema.String),
    phoneNumber: Schema.optional(Schema.String),
});
export const MeetingTimes = Schema.Struct({
    interviewer: PersonConfig,
    interviewee: PersonConfig,
    timeSlots: Schema.Array(TimeSlot),
    workingHours: Schema.partial(WorkingHours),
    isGenericLink: Schema.Boolean,
});
