import { useMemo } from "react";
import { useDocSnapshot, useQuerySnapshot, useMissionControlDB, } from "@aglocal/web/firebase";
import UniqueList from "@aglocal/web/helpers/UniqueList";
import { isExcludedSkill } from "@aglocal/web/helpers/filterSkills";
import normalizeSkill from "@aglocal/web/helpers/normalizeSkill";
const DEFAULT_EXCLUDED_ENTITIES = ["SKILL"];
export default function useLLMNER(id, exclude = DEFAULT_EXCLUDED_ENTITIES) {
    const db = useMissionControlDB();
    const tempOutput = useQuerySnapshot(db.llmnerTemp.subcollections(id).output.query());
    const modelOutput = useDocSnapshot(db.modelOutput.doc(id));
    const result = useMemo(() => {
        const entities = new UniqueList(null, ({ label, text }) => `${label}:${normalizeSkill(text)}`);
        const addEntity = (entity) => {
            if (exclude.includes(entity.label)) {
                return;
            }
            if (isExcludedSkill(entity.text)) {
                return;
            }
            entities.add(entity);
        };
        modelOutput.snapshot?.data()?.llmnerModel?.entities.forEach(addEntity);
        tempOutput.snapshot?.forEach((doc) => {
            addEntity(doc.data());
        });
        return Array.from(entities);
    }, [exclude, modelOutput.snapshot, tempOutput.snapshot]);
    return [
        result,
        modelOutput.loading || tempOutput.loading,
        modelOutput.error ?? tempOutput.error,
    ];
}
