import { Conference } from "./Conferences.js";
import { DarkquellInferredSkill } from "./Darkquell.js";
import { GenericLink } from "./GenericLink.js";
import { GraphReference } from "./GraphReference.js";
import { Interview } from "./Interview.js";
import { InterviewSummary } from "./InterviewSummary.js";
import { LLMNEREntity } from "./LLM-NER.js";
import { MeetingStatus } from "./MeetingStatus.js";
import { MissionControlConfig } from "./MissionControlConfig.js";
import { ModelOutput } from "./ModelOutput.js";
import { RealtimeTranscript } from "./Transcript.js";
import { RecallBot } from "./Recall.js";
import { RecruiterSettings } from "./RecruiterSettings.js";
import { TalentMeetingDetails } from "./TalentMeetingDetails.js";
import { TeamsConversation } from "./TeamsConversations.js";
import { UserConfig } from "./UserConfigSchema.js";
export const MissionControl = {
    conferences: {
        schema: Conference,
    },
    config: {
        schema: MissionControlConfig,
    },
    darkquellTemp: {
        name: "darkquell_temp",
        subcollections: {
            skills: {
                schema: DarkquellInferredSkill,
            },
        },
    },
    genericLinks: {
        schema: GenericLink,
    },
    graphReference: {
        schema: GraphReference,
    },
    interviews: {
        schema: Interview,
    },
    interviewSummaries: {
        schema: InterviewSummary,
    },
    llmnerTemp: {
        name: "llmner_temp",
        subcollections: {
            output: {
                schema: LLMNEREntity,
            },
        },
    },
    meetingStatus: {
        schema: MeetingStatus,
    },
    modelOutput: {
        schema: ModelOutput,
    },
    recallBots: {
        schema: RecallBot,
    },
    recruiterSettings: {
        schema: RecruiterSettings,
    },
    rrts: {
        schema: RealtimeTranscript,
    },
    talentMeetingDetails: {
        schema: TalentMeetingDetails,
    },
    teamsConversations: {
        schema: TeamsConversation,
    },
    userConfig: {
        schema: UserConfig,
    },
};
