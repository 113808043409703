import { useEffect } from "react";
import { Control } from "react-hook-form";

import type { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";

import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormWithSummary } from "../form";

import TextField from "./TextField";

export interface LocationCommentsProps {
  details: Readonly<TalentMeetingDetails> | undefined;
  control: Control<PostMeetingFormWithSummary>;
  setDefault: UseFormSetDefault<PostMeetingFormWithSummary>;
}

export default function LocationComments({
  details,
  control,
  setDefault,
}: LocationCommentsProps) {
  const defaultValue = details?.locationPreferences?.comments ?? "";

  useEffect(() => {
    setDefault("locationComments", defaultValue);
  }, [defaultValue, setDefault]);

  return (
    <TextField
      name="locationComments"
      label="Location Comments"
      control={control}
    />
  );
}
