import { useEffect, useMemo, useState } from "react";
import { getCountFromServer, getDoc, getDocs, onSnapshot, queryEqual, refEqual, } from "firebase/firestore";
import useDedupe from "@aglocal/web/hooks/useDedupe";
import useAsync from "@aglocal/web/hooks/useAsync";
function useAsyncAsSnapshot({ result, loading, error, }) {
    return useMemo(() => ({ snapshot: result, loading, error }), [result, loading, error]);
}
function useDocumentSnapshotAsDataTuple({ snapshot, loading, error, }) {
    return useMemo(() => [snapshot?.data(), loading, error], [snapshot, loading, error]);
}
function useQuerySnapshotAsDataTuple({ snapshot, loading, error, }) {
    return useMemo(() => [snapshot?.docs.map((doc) => doc.data()), loading, error], [snapshot, loading, error]);
}
export function asType() {
    return {
        toFirestore(data) {
            return data;
        },
        fromFirestore(snapshot, options) {
            return snapshot.data(options);
        },
    };
}
export function useDocSnapshotOnce(reference) {
    const referenceMemo = useDedupe(reference, refEqual);
    const result = useAsync(() => getDoc(referenceMemo), [referenceMemo]);
    return useAsyncAsSnapshot(result);
}
export function useDocOnce(reference) {
    const result = useDocSnapshotOnce(reference);
    return useDocumentSnapshotAsDataTuple(result);
}
export function useQuerySnapshotOnce(query) {
    const queryMemo = useDedupe(query, queryEqual);
    const result = useAsync(() => getDocs(queryMemo), [queryMemo]);
    return useAsyncAsSnapshot(result);
}
export function useQueryOnce(query) {
    const result = useQuerySnapshotOnce(query);
    return useQuerySnapshotAsDataTuple(result);
}
export function useDocSnapshot(reference, options) {
    const referenceMemo = useDedupe(reference, refEqual);
    const [state, setState] = useState({ loading: true });
    useEffect(() => {
        return onSnapshot(referenceMemo, options ?? {}, {
            next: (snapshot) => {
                setState({ loading: false, snapshot });
            },
            error: (error) => {
                setState({ loading: false, error });
            },
        });
    }, [setState, referenceMemo, options]);
    return state;
}
export function useDoc(reference, options) {
    const result = useDocSnapshot(reference, options);
    return useDocumentSnapshotAsDataTuple(result);
}
export function useQuerySnapshot(query, options) {
    const queryMemo = useDedupe(query, queryEqual);
    const [state, setState] = useState({ loading: true });
    useEffect(() => {
        return onSnapshot(queryMemo, options ?? {}, {
            next: (snapshot) => {
                setState({ loading: false, snapshot });
            },
            error: (error) => {
                setState({ loading: false, error });
            },
        });
    }, [setState, queryMemo, options]);
    return state;
}
export function useQuery(query, options) {
    const result = useQuerySnapshot(query, options);
    return useQuerySnapshotAsDataTuple(result);
}
export function useCountSnapshot(query) {
    const queryMemo = useDedupe(query, queryEqual);
    const result = useAsync(() => getCountFromServer(queryMemo), [queryMemo]);
    return useAsyncAsSnapshot(result);
}
export function useCount(query) {
    const { snapshot, loading, error } = useCountSnapshot(query);
    return useMemo(() => [snapshot?.data().count, loading, error], [snapshot, loading, error]);
}
