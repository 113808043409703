import { useMemo } from "react";
import { useDocSnapshot, useQuerySnapshot, useMissionControlDB, } from "@aglocal/web/firebase";
import { isExcludedSkill } from "@aglocal/web/helpers/filterSkills";
import normalizeSkill from "@aglocal/web/helpers/normalizeSkill";
import UniqueList from "@aglocal/web/helpers/UniqueList";
export default function useDarkquell(id) {
    const db = useMissionControlDB();
    const tempOutput = useQuerySnapshot(db.darkquellTemp.subcollections(id).skills.query());
    const modelOutput = useDocSnapshot(db.modelOutput.doc(id));
    const result = useMemo(() => {
        const skills = [];
        const index = new Map();
        const addSkill = ({ skill, sources, maxConfidence, }) => {
            if (isExcludedSkill(skill.name)) {
                return;
            }
            const accumulator = index.get(skill.id);
            if (accumulator == null) {
                skills.push(skill);
                index.set(skill.id, {
                    sources: new UniqueList(sources, ({ text }) => normalizeSkill(text)),
                    maxConfidence,
                });
            }
            else {
                accumulator.maxConfidence = Math.max(accumulator.maxConfidence, maxConfidence);
                sources.forEach((source) => {
                    accumulator.sources.add(source);
                });
            }
        };
        modelOutput.snapshot?.data()?.darkquell?.skills?.forEach(addSkill);
        tempOutput.snapshot?.forEach((doc) => {
            addSkill(doc.data());
        });
        return skills.map((skill) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { sources, maxConfidence } = index.get(skill.id);
            return { skill, maxConfidence, sources: Array.from(sources) };
        });
    }, [modelOutput.snapshot, tempOutput.snapshot]);
    return [
        result,
        modelOutput.loading || tempOutput.loading,
        modelOutput.error ?? tempOutput.error,
    ];
}
