import {
  Control,
  FieldPathByValue,
  FieldValues,
  useController,
} from "react-hook-form";

import { Field, FieldProps, Input } from "@fluentui/react-components";

export interface StringFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends FieldProps {
  name: TName;
  control: Control<TFieldValues>;
}

export default function StringField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>({ name, control, ...props }: StringFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({
    name,
    control,
  });

  return (
    <Field
      validationState={error ? "error" : undefined}
      validationMessage={error?.message}
      {...props}
    >
      <Input {...field} disabled={isSubmitting || isSubmitSuccessful} />
    </Field>
  );
}
